/* form{
    width: 250px;
    margin: 0 auto;
    margin-top: 50px;
} */
.group{
    margin-bottom: 15px;
}
label{
    display: block;
}
button{
    width: auto;
}