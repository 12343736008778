.titre{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 40vh;
    font-weight: bolder;
    font-size:5vh;
}
.container{
    background-image: url(../../../_images/logo.png);
    opacity: 0.5;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 74vh;

}
.container h1{
    opacity: 1;
}