.header{
    text-align: center;
    border-bottom: solid 1px black;
}

/* .header ul{
    list-style: none;
    padding: 0px;
}
.header ul li{
    margin-right: 20px;
    display: inline-block;
} */
.header h1{
    font-size: 80px;
    text-align: center;
    background-color: lightcyan;
    display: inline-block;
    width: 100%;
    font-family:Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif ;
}
.header img{
    width:150px;
    display: inline-block;
    margin-right: 125px;
    margin-top: 15px;
}
/* .header a{
    text-decoration: none;
    color: black;
    font-weight: bold;
}
.header nav{
    border-bottom: solid 1px black;
} */