.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.site{
  min-height: 100vh;
  /* margin-bottom: -140px; */
}

.container{
  margin-left: 40px;
  margin-right: 40px;
  text-align: justify;
}

footer, .minsite {
  height: 140px;
}

.imgFB, .imgTwitter{
  height: 75px;
}

.imgYT{
  height: 25px;
}

@media only screen and (max-width:720px){
  .imgFB, .imgTwitter{
      height: 40px;
  }

  .imgYT{
      height: 20px;
  }
  
  .site{
      min-height: 100vh;
      /* margin-bottom: -90px; */
  }

  footer, .minsite {
      height: 90px;
  }
      
}